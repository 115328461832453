<template>
    <b-form-group
:label="field.placeholder"
:label-for="field.id"
>
        <b-form-file
            :id="field.id"
            v-model="field.value"
            :placeholder="field.placeholder"
            :required="field.required"
            :accept="field.accept_file_type"
            :disabled="field.disabled"
        />
    </b-form-group>
</template>
<script>
import { BFormFile, BFormGroup } from 'bootstrap-vue';
export default {
    name: 'CustomText',
    components: {
        BFormFile,
        BFormGroup,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
};
</script>
