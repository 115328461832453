<template>
    <b-col
        md="4"
    >
        <b-form-group
            label="Customer"
        >
            <b-form-input
                :id="field.value"
                v-model="item"
                :value="clientId"
                :reduce="clientId"
                :disabled="branchId==0"
                maxlength="250"
                @input="resClient(branchId)"
            />
            <b-list-group v-if="filtersearches && listview">
                <b-list-group-item
                    v-for="filtersearch in filtersearches"
                    :key="filtersearch.id"
                    @click="setclient(filtersearch.client_name, filtersearch.id)"
                >
                    {{ filtersearch.client_name }}
                </b-list-group-item>
            </b-list-group>
        </b-form-group>
    </b-col>
</template>

<script>
  import {
      BFormInput,
      BFormGroup,
      BListGroup,
      BListGroupItem,
      BCol,
      } from 'bootstrap-vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  export default {
    name: "Autocomplete",
    components: {
      BListGroup,
      BListGroupItem,
      BFormInput,
      BFormGroup,
      BCol,
    },
    props: {
        branchId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            filtersearches: '',
            item: '',
            items: '',
            field: '',
            listview: false,
            clientId: 0,
        }
    },
    watch: {
        item() {
            this.clientfilter();
        },
        clientId(val) {
            console.log(val);
            this.$emit('changed', val);
        },
    },
    methods: {
        resClient(branchId) {
            if (this.item.length > 3) {
                this.listview = true;
                this.callClient({
                    search: this.item,
                    branch_id: branchId,
                });
            }
        },
        callClient(data) {
            const params = { ...data };
            this.$helper.showLoading();
            this.$axios
                .post(`vehicles/getClientData`, params)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        // console.log(resp.data);
                        this.field = resp.data;
                        this.clientfilter();
                    }
                })
                .catch(err => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        setclient(cname, cid) {
            this.clientId = cid;
            this.item = cname;
            this.field.value = cid;
            console.log(this.clientId);
            this.listview = false;
        },
        clientfilter() {
            this.items = this.field;
            this.filtersearches = this.items.filter(item => item.client_name.toLowerCase().includes(this.item.toLowerCase()));
        },
    },
};
</script>
