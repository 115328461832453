<template>
    <b-form-group
        :label="field.placeholder"
        :label-for="field.id"
    >
        <b-form-datepicker
            :id="field.id"
            v-model="field.value"
            local="en"
            :placeholder="field.placeholder"
            :required="field.required"
            :name="field.db_name"
            :disabled="field.disabled"
            class="mb-1"
            selected-variant="primary"
        />
    </b-form-group>
</template>
<script>
import { BFormDatepicker, BFormGroup } from 'bootstrap-vue';
export default {
    name: 'CustomCalender',
    components: {
        BFormDatepicker,
        BFormGroup,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
};
</script>
