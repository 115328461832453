<style lang="scss">
	.role-permission {
		#permissions-table {
			border-spacing: 0;
			max-height: 300px;
			.px-3 {
				padding-left: 0.75rem !important;
				padding-right: 0.75rem !important;
			}
			.py-2 {
				padding-top: 0.5rem !important;
				padding-bottom: 0.5rem !important;
			}
			.p-5 {
				padding: 1.25rem !important;
			}

			thead {
				position: sticky;
				z-index: 999;
				top: 0px;
				th {
					min-width: 125px;
					vertical-align: middle;
					// border-bottom: 1px solid #ffffff;
				}
				th:first-child {
					// border-right: 1px solid #ffffff;
					position: sticky;
					min-width: 180px;
					left: 0px;
					z-index: 9998;
				}
			}

			tbody {
				height: 400px;
				tr {
					td:first-child {
						// border-right: 1px solid #ffffff;
						background-color: #04297f;
						color: #ffffff;
						position: sticky;
						left: 0px;
						min-width: 180px;
						z-index: 99;
					}
					td {
						min-width: 125px;
						.abc-checkbox {
							padding-left: 0;
							label::before {
								margin-left: 0;
							}
							label::after {
								margin-left: 0;
							}
							input[type="checkbox"] {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	tbody#permissions-table-tbody tr td:first-child {
		background-color: #04297f;
		color: #fff;
	}

</style>
<template>
	<b-card
		no-body
		class="role-permission ma-2 mt-8 container-fluid"
	>
		<div
			class="d-block d-flex"
			style="overflow-x: auto; max-height: 700px;"
		>
			<table
				id="permissions-table"
				class="d-block mb-2 mt-2 table table-borderless table-responsive"
			>
				<thead
				id="permissions-table-thead"
				class="thead-dark"
				>
					<tr>
						<th class="p-5">
							Module
						</th>
						<th
							v-for="mod in modules"
							:key="mod.code"
							width="100"
						>{{ mod.name }}</th>
					</tr>
				</thead>
				<tbody
					id="permissions-table-tbody"
					@scroll="permissionTbodyScroll()"
				>
					<tr
						v-for="(action, inx) in actions"
						:key="action.code"
					>
						<td class="p-5">
							{{ action.name }}
							<a @click="$bvToast.show(`example-toast${inx}`)"><feather-icon icon="HelpCircleIcon" /></a>
							<b-toast :id="`example-toast${inx}`" title="Tips" static no-auto-hide>
								{{ action.description }}
							</b-toast>
						</td>
						<td
							v-for="inMod in modules"
							:key="inMod.code"
							width="100"
						>
							<div class="abc-checkbox">
								<b-form-checkbox
									switch
									class="ml-0 mr-0"
									:name="`${inMod.code}-${action.code}`"
									:checked="permissions.indexOf(`${inMod.code}-${action.code}`) >= 0"
									@change="permit(`${inMod.code}-${action.code}`)"
								/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</b-card>
</template>
<script>
	import {
		BCard, BFormCheckbox, BToast,
	} from 'bootstrap-vue';
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

	export default {
		components: {
			BCard,
			BFormCheckbox,
			BToast,
		},
		props: {
			roleId: {
				type: String,
				default: "0",
			},
			data: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				modules: [],
				actions: [],
				permissions: [],
			}
		},
		created() {
			// this.permission.header.splice(0, 1);
			this.modules = this.data.modules;
			this.actions = this.data.actions;
			this.permissions = this.data.permissions;
			// console.log(this.data, this.modules, this.actions, this.permissions)
		},
		methods: {
			checkIfSelected(module, action) {
				return false;
			},
			permissionCreate(action, scope, module) {
				return (
					`${action.toLowerCase()}-${scope}-${module.toLowerCase()}`
				);
			},
			permissionCheckboxSelected(action, scope, module) {
				const code = this.permissionCreate(action, scope, module);
				const url =					`/${this.module}/${
					this.permission.checkbox.indexOf(code) >= 0
						? "unassign"
						: "assign"}`;
				this.$http
					.post(url, { id: this.id, permission: code })
					.then((response) => {
						const message =							this.permission.checkbox.indexOf(code) >= 0
								? "Unassigned"
								: "Assigned";
						if (response.status === 200) {
							if (this.permission.checkbox.indexOf(code) >= 0) {
								this.permission.checkbox.splice(
									this.permission.checkbox.indexOf(code),
									1,
								);
							} else {
								this.permission.checkbox.push(code);
							}
							// console.log(this.permission.checkbox);
							this.$helper.showSuccessMessage(this, message);
						}
					})
					.catch((error) => {
						this.$helper.showErrorMessage(this, error);
					});
			},
			permissionTbodyScroll() {
				const thead = document.getElementById("permissions-table-thead");
				const tbody = document.getElementById("permissions-table-tbody");
				const tbodyScrollLeft = tbody.scrollLeft;
				thead.style.left = `${-tbodyScrollLeft}px`;
				const table = document.getElementById("permissions-table");
				for (let i = 0; i < table.rows.length; i += 1) {
					const firstCol = table.rows[i].cells[0];
					firstCol.style.left = `${tbodyScrollLeft}px`;
				}
				const permissionsTableTheadFirstChild = document.getElementById(
					"permissions-table-thead",
				).firstElementChild;
				permissionsTableTheadFirstChild.style.left = `${tbodyScrollLeft}px`;
			},
			permit(permission) {
				const index = this.permissions.indexOf(permission);
				if (index >= 0) {
					this.permissions.splice(index, 1);
					this.callServer('roles/unassign', permission);
				} else {
					this.permissions.push(permission);
					this.callServer('roles/assign', permission);
				}
			},
			callServer(url, permission) {
				this.$axios.post(url, { id: this.roleId, permission }).then(resp => {
                    if (resp.status === 200) {
                        this.$toast({
                            component: ToastificationContent,
                                props: {
                                    title: 'Permission Changed',
                                    icon: 'CheckCircleIcon',
                                    variant: 'Primary',
                                },
                        });
						if (this.$store.state.auth.user.roles[0].id === parseInt(this.roleId, 10)) { this.$store.dispatch('auth/attempt'); }
                    }
                }).catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                            props: {
                                title: 'Oops! Something went wrong',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                });
			},
		},
	};
</script>
