<template>
    <b-form-group
:label="field.placeholder"
:label-for="field.id"
>
        <b-form-input
            :id="field.id"
            v-model="field.value"
            :placeholder="field.placeholder"
            :required="field.required"
            :name="field.db_name"
            :disabled="field.disabled"
            type="email"
        />
    </b-form-group>
</template>
<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue';
export default {
    name: 'CustomEmail',
    components: {
        BFormInput,
        BFormGroup,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
};
</script>
