<template>
    <b-form-group
        :label="field.placeholder"
        :label-for="field.id"
    >
        <!-- <v-select
            :id="field.id"
            v-model="field.value"
            :placeholder="field.placeholder"
            :rules="[(v) => !!v || 'Please Select One']"
            :name="field.db_name"
            :disabled="field.disabled"
            label="name"
            :reduce="item => item.id"
            :options="field.options"
            required
        /> -->
        <b-form-select
            :id="field.id"
            v-model="field.value"
            :name="field.db_name"
            text-field="name"
            value-field="id"
            :disabled="field.disabled"
            :options="field.options"
            :placeholder="field.placeholder"
            :required="field.required"
        />
    </b-form-group>
</template>
<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue';
import VSelect from "vue-select";
export default {
    name: 'CustomSelect',
    components: {
        BFormGroup, BFormSelect,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
};
</script>
