<template>
<b-card>
    <b-form @submit="onSubmit">
        <b-row>
            <b-col
                v-for="field in fields_array"
                :key="field.id"
                :md="findWidth(field.width)"
            >
                <component
                    :is="
                        `Custom${field.type[0].toUpperCase() +
                            field.type.slice(1)}`
                    "
                    :field="field"
                />
            </b-col>
        </b-row>
        <b-row>
            <role-permission
                v-if="showRolePermissions"
                :id="rolePermissionId"
                :role-id="id"
                :data="extra_data"
            />
        </b-row>
        <b-button
type="submit"
variant="primary"
>
            Submit
        </b-button>
        <GoBack />
    </b-form>
</b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import VSelect from "vue-select";
import RolePermission from './rolepermission';

import CustomText from './components/CustomText';
import CustomTextarea from './components/CustomTextarea';
import CustomSwitch from './components/CustomSwitch';
import CustomEmail from './components/CustomEmail';
import CustomPassword from './components/CustomPassword';
import CustomSelect from './components/CustomSelect';
import CustomCkeditor from './components/CustomCkeditor';
import CustomCalender from './components/CustomCalender';
import CustomFile from './components/CustomFile';
import CustomRadio from './components/CustomRadio.vue';
import CustomMulSelect from './components/CustomMulSelect.vue';
import CustomClientSelect from './components/CustomClientSelect';
import GoBack from './goBack.vue';

export default {
    components: {
        ToastificationContent,
        BRow,
        BCol,
        CustomText,
        BForm,
        BButton,
        CustomTextarea,
        CustomSwitch,
        CustomEmail,
        CustomPassword,
        CustomSelect,
        RolePermission,
        CustomCkeditor,
        CustomCalender,
        CustomFile,
        CustomRadio,
        CustomMulSelect,
        CustomClientSelect,
        BCard,
        GoBack,
    },
    directives: {
        Ripple,
    },
    props: {
        propModule: {
            type: String,
            required: false,
            default: null,
        },
    },
    data: () => ({
        module: '',
        fields_array: [],
        id: 0,
        url: '',
        result: {},
        extra_data: {},
        rolePermissionId: 0,
    }),
    computed: {
        showRolePermissions() {
            return (
                this.module === 'roles'
                && !this.lodash.isEmpty(this.extra_data)
                && this.id !== 0
            );
        },
    },
    created() {
        this.module = this.propModule
            ? this.propModule
            : this.$route.params.modules;
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            this.url = `/${this.module}/view/${this.id}`;
        } else {
            this.url = `/${this.module}/createView`;
        }
        this.getCreateData();
    },
    methods: {
        async getCreateData() {
            this.$helper.showLoading();
            const response = await this.$axios.get(this.url).catch(error => {
                console.error(error);
                this.$helper.hideLoading();
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Oops! Something went wrong',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                });
            });
            this.$helper.hideLoading();
            if (
                this.id
                && response.data.results
                && response.data.results.result
            ) {
                this.result = response.data.results.result;
            }
            this.fields_array = Object.values(response.data.fields);
            this.fields_array = this.fields_array
                .filter(
                    obj => ('show_in_form' in obj && obj.show_in_form)
                        || !('show_in_form' in obj),
                )
                .map(obj => {
                    if (obj.db_name in this.result) {
                        // eslint-disable-next-line no-param-reassign
                        obj.value = this.result[obj.db_name];
                        if (obj.type === 'switch') {
                            const boolval = this.result[obj.db_name] === 1;
                            // eslint-disable-next-line no-param-reassign
                            obj.value = boolval;
                            console.log('$#$', boolval);
                        }
                    }
                    return obj;
                });
            if (this.module === 'roles') {
                if (this.url.search('createView') === -1 && this.id.toString() === '1') {
                    this.fields_array.forEach(itm => {
                        if (itm.field_name === 'name') {
                            itm.disabled = true; // eslint-disable-line no-param-reassign
                        }
                    })
                }
                this.extra_data = {
                    modules: response.data.results.modules,
                    actions: response.data.results.actions,
                    permissions: response.data.results.permissions,
                };
                this.rolePermissionId += 1;
            }
        },
        findWidth(width) {
            switch (width) {
                case '50':
                    return 6;
                case '100':
                    return 12;
                case '25':
                    return 3;
                default:
                    return 6;
            }
        },
        async onSubmit(event) {
            event.preventDefault();
            let hasFile = false;
            let request = {};
            this.fields_array.forEach(field => {
                if (field.type === 'file') {
                    hasFile = true;
                }
                request[field.db_name] = field.value;
                console.log(field.value);
            });
            let header = null;
            if (hasFile) {
                header = {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                };
                const formRequest = new FormData();
                Object.keys(request).forEach(key => {
                    formRequest.append(key, request[key]);
                });
                request = formRequest;
            }
            let url = '';
            if (this.id) {
                url = `/${this.module}/update/${this.id}`;
            } else {
                url = `/${this.module}/save`;
            }
            const response = await this.$axios
                .post(url, request, header)
                .catch(error => {
                    console.error(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
            if (
                this.id === 0
                && response.data.results
                && response.data.results.id > 0
            ) {
                this.$router.push(`/account/${this.module}`).catch(() => {});
            } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Record Updated',
                        icon: 'CheckIcon',
                        variant: 'primary',
                    },
                });
            }
        },
    },
};

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
