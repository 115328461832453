<template>
    <b-form-group
        :label="field.placeholder"
        :label-for="field.id"
    >
        <ckeditor
            v-model="field.value"
            tag-name="textarea"
            :editor="editor"
            :config="editorConfig"
        /></b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'CustomCkeditor',
    components: {
        BFormGroup,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                extraAllowedContent: true,
                allowedContent: true,
            },
        }
    },

};
</script>
